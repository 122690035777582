'use client';

import { cloneElement, forwardRef, HTMLProps, isValidElement, ReactNode } from 'react';

import { useModalContext } from '@uikit';

import { useMergeRefs } from '@floating-ui/react';

interface ModalTriggerProps extends HTMLProps<HTMLElement> {
  children: ReactNode;
  asChild?: boolean;
}

const ModalTrigger = forwardRef<HTMLElement, ModalTriggerProps>(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = useModalContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      );
    }

    return (
      <button
        ref={ref}
        // The user can style the trigger based on the state
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  },
);

export default ModalTrigger;
